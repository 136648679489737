"use strict";
/* export interface IErrorParams {
    duration?: number;
    target?: HTMLElement;
    scrollOffset?: number;
} */
Object.defineProperty(exports, "__esModule", { value: true });
exports.linksIconsSociais = void 0;
var linksIconsSociais = /** @class */ (function () {
    function linksIconsSociais() {
        this.init();
    }
    linksIconsSociais.prototype.init = function () {
        //////////////////////////////////////////////////////////////////////// MOBILE
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf('android') > -1;
        var isIphone = ua.indexOf('iphone') > -1;
        var isIpad = ua.indexOf('ipad') > -1;
        if (window.matchMedia('(min-width: 768px)').matches && !isAndroid && !isIphone && !isIpad) { // DESKTOP
            var sociais_links = document.querySelectorAll('.sociais-link');
            var sociais_links_total = Number(sociais_links.length);
            for (var $scf = 0; $scf < sociais_links_total; ++$scf) {
                sociais_links[$scf].addEventListener('mouseenter', function (event) {
                    event.preventDefault();
                    var this_current_target = event.currentTarget;
                    // thisCurrentTarget.style.backgroundColor = String(thisCurrentTarget.dataset.bgcol);
                    var svg_el = this_current_target.querySelector('svg');
                    var svg_paths = svg_el.querySelectorAll('path');
                    var svg_paths_total = Number(svg_paths.length);
                    for (var $svgp = 0; $svgp < svg_paths_total; ++$svgp) {
                        // console.log('svg_paths[$svgp]', svg_paths[$svgp], this_current_target.dataset.bgcol);
                        // svg_paths[$svgp].setAttribute('fill', String(this_current_target.dataset.bgcol));
                        svg_paths[$svgp].style.fill = String(this_current_target.dataset.bgcol);
                    }
                });
                sociais_links[$scf].addEventListener('mouseleave', function (event) {
                    event.preventDefault();
                    var this_current_target = event.currentTarget;
                    var svg_el = this_current_target.querySelector('svg');
                    var svg_paths = svg_el.querySelectorAll('path');
                    var svg_paths_total = Number(svg_paths.length);
                    for (var $svgp = 0; $svgp < svg_paths_total; ++$svgp) {
                        // svg_paths[$svgp].setAttribute('fill', '#ffffff');
                        // svg_paths[$svgp].style.fill = "#ffffff";
                        svg_paths[$svgp].style.fill = "currentColor";
                    }
                    // thisCurrentTarget.style.backgroundColor = '#ffffff';
                });
            }
        }
    };
    return linksIconsSociais;
}());
exports.linksIconsSociais = linksIconsSociais;
exports.default = linksIconsSociais;
