"use strict";
/* export interface IErrorParams {
    duration?: number;
    target?: HTMLElement;
    scrollOffset?: number;
} */
Object.defineProperty(exports, "__esModule", { value: true });
exports.mobileScreenHeight = void 0;
var mobileScreenHeight = /** @class */ (function () {
    function mobileScreenHeight() {
        this.init();
    }
    mobileScreenHeight.prototype.init = function () {
        //////////////////////////////////////////////////////////////////////// MOBILE
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf('android') > -1;
        var isIphone = ua.indexOf('iphone') > -1;
        var isIpad = ua.indexOf('ipad') > -1;
        //////////////////////////////////////////////////////////////////////// HEIGHT 100VH MOBILE
        // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
        // screen.orientation.angle: 0 (portrait) ou melhor window.matchMedia("(orientation: portrait)").matches
        // screen.orientation.angle: 90 (landscape) ou melhor window.matchMedia("(orientation: landscape)").matches
        //https://stackoverflow.com/questions/12452349/mobile-viewport-height-after-orientation-change/49383279#49383279
        function mobileScreenHeightFunc() {
            // console.log(window.matchMedia('(min-width: 768px)').matches, !isAndroid, !isIphone, !isIpad, screen.orientation.angle, ua);
            var header_el = document.querySelector('.header');
            var header_el_exists = document.body.contains(header_el);
            var header_overlay = document.querySelector('.header__overlay');
            var header_overlay_exists = document.body.contains(header_overlay);
            if (window.matchMedia('(min-width: 768px)').matches && !isAndroid && !isIphone && !isIpad) { // DESKTOP //
                // console.log('DESKTOP');
            }
            else if (window.matchMedia('(min-width: 768px)').matches && window.matchMedia("(orientation: portrait)").matches) { // IPAD + ANDROID TABLET PORTRAIT
                // console.log('TABLET PORTRAIT');
                if (header_el_exists === true) {
                    header_el.style.height = Number(window.innerHeight) + 'px';
                }
                if (header_overlay_exists === true) {
                    header_overlay.style.height = Number(window.innerHeight) + 'px';
                }
                // profsMobile();
            }
            else if (isAndroid && !isIpad || isIphone && !isIpad) { // MOBILE
                // console.log('MOBILE');
                // console.log('mobileScreenHeight', screen.orientation.angle, window.innerHeight, screen.height);
                if (header_el_exists === true) {
                    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
                    // https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
                    var vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty('--vh', vh + "px");
                    // header_el.style.height = Number(window.innerHeight) + 'px';
                }
                if (header_overlay_exists === true) {
                    header_overlay.style.height = Number(window.innerHeight) + 'px';
                }
                document.body.classList.add('mobile');
                // profsMobile();
            }
        }
        /* window.addEventListener("orientationchange", function(event: Event) {
            var afterOrientationChange = function() {
                mobileScreenHeightFunc();
                window.removeEventListener('resize', afterOrientationChange);
            };
            window.addEventListener('resize', afterOrientationChange);
        }); */
        /////////////////////////////////////////// LA-ARCHSTUDIO
        window.addEventListener('resize', function () {
            // console.log('resize', screen.orientation.angle, window.innerWidth, window.innerHeight);
            mobileScreenHeightFunc();
        });
    };
    return mobileScreenHeight;
}());
exports.mobileScreenHeight = mobileScreenHeight;
exports.default = mobileScreenHeight;
