"use strict";
/* export interface IErrorParams {
    duration?: number;
    target?: HTMLElement;
    scrollOffset?: number;
} */
Object.defineProperty(exports, "__esModule", { value: true });
exports.menuMobileOverlay = void 0;
var menuMobileOverlay = /** @class */ (function () {
    function menuMobileOverlay() {
        this.init();
    }
    menuMobileOverlay.prototype.init = function () {
        var navbar_toggler_custom = document.querySelector('.navbar-toggler-custom');
        var menu_mobile_overlay = document.querySelector('.menu__mobile-overlay');
        navbar_toggler_custom.addEventListener('click', function (event) {
            event.preventDefault();
            menu_mobile_overlay.classList.add('open');
        });
        var close_btn = document.querySelector('.close__btn');
        close_btn.addEventListener('click', function (event) {
            event.preventDefault();
            menu_mobile_overlay.classList.remove('open');
        });
    };
    return menuMobileOverlay;
}());
exports.menuMobileOverlay = menuMobileOverlay;
exports.default = menuMobileOverlay;
