"use strict";
/* export interface IErrorParams {
    duration?: number;
    target?: HTMLElement;
    scrollOffset?: number;
} */
Object.defineProperty(exports, "__esModule", { value: true });
exports.imagesGalleryLightGallery = void 0;
// https://www.lightgalleryjs.com/docs/getting-started/
require("lightgallery.js");
// import lightGallery from 'lightgallery';
// npm install lightgallery.js
var imagesGalleryLightGallery = /** @class */ (function () {
    function imagesGalleryLightGallery() {
        this.init();
    }
    imagesGalleryLightGallery.prototype.init = function () {
        var galeria_el = document.querySelector('.galeria');
        // const galeria_el: HTMLElement = <HTMLElement>document.getElementById('imagesgridlightgalleryiframeimgtext');
        var galeria_el_exists = document.body.contains(galeria_el);
        if (galeria_el_exists === true) {
            lightGallery(galeria_el, {
                selector: '.galeria-link',
                mode: 'lg-fade',
                download: false,
                counter: false,
                slideEndAnimatoin: false,
                getCaptionFromTitleOrAlt: false,
                loop: false,
                enableDrag: false,
                thumbnail: false,
                pager: false,
                iframeMaxWidth: '80%'
                // exThumbImage: 'data-thumb',
                // cssEasing: 'cubic-bezier(0.680, -0.550, 0.265, 1.550)'
            });
        }
    };
    return imagesGalleryLightGallery;
}());
exports.imagesGalleryLightGallery = imagesGalleryLightGallery;
exports.default = imagesGalleryLightGallery;
